.MuiPaper-rounded {
	border-radius: 10px;
}

.one-button-dialog-container {
	text-align: center;
	max-width: 598px;
	padding: 30px 30px;
	min-width: 300px;
}
.one-button-dialog-h1 {
	color: #494d54;
	font-size: var(--font-size4);
	text-align: center;
	margin-bottom: 15px;
}

.one-button-dialog-text {
	font-size: var(--font-size7);
	color: var(--color-gray-hover);
	margin-bottom: 25px;
	line-height: 14px;
}

.one-button-dialog-button {
	display: flex;
	justify-content: center;
	> span {
		padding: 6px 60px;
		background: var(--color-main);
		color: #ffffff;
		border: 1px solid var(--color-main);
		transition: 0.1s ease-in-out;
		cursor: pointer;
		&:hover {
			background: var(--color-main-hover);
			color: var(--color-blue-hover);
		}
	}
}

.two-button-dialog-button-container {
	display: flex;
	justify-content: center;

	> div {
		cursor: pointer;
		font-size: var(--font-size6);
		padding: 9px 61px;

		&:first-child {
			color: var(--color-main);
			background: var(--color-border);
			border: 1px solid var(--color-border);
			transition: 0.1s ease-in-out;
			margin-right: 5px;
			border-radius: 4px;

			&:hover {
				color: var(--color-main);
				background: var(--color-main-hover);
			}
		}

		&:last-child {
			color: #ffffff;
			background: var(--color-main);
			border: 1px solid var(--color-main);
			transition: 0.1s ease-in-out;
			margin-left: 5px;
			border-radius: 4px;

			&:hover {
				color: var(--color-blue-hover);
				background: var(--color-main-hover);
			}
		}
	}
}
