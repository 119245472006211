.side-menu-wrapper {
	// position: fixed;
	z-index: 1000;
	display: flex;
	// min-height: calc(100vh - var(--header-height));
	height: 100%;
	width: 100%;
	// height: var(--header-height);
	overflow: auto;
	.side-container {
		position: fixed;
		width: 15%;
		min-height: 100vh;
		min-width: var(--side-width);
		max-width: var(--side-width);
		background-color: #ffffff;
		border-right: 1px solid #e3e5e9;
		.user-info-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			padding: 17px 15px;
			border-bottom: 1px solid #eeeeee;
			row-gap: 23px;
			.user-name {
				font-size: var(--font-size4);
				font-weight: 700;
			}
			.button-area {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
			.profile-icon {
				margin-right: 9px;
			}

			.logout-icon {
				width: 25px;
				height: 25px;
				cursor: pointer;
			}
		}
	}

	.menu-item {
		padding-left: 28px;
		padding-right: 28px;
		height: 51px;
		font-weight: 500 !important;
		display: flex;
		position: relative;
		cursor: pointer;
		a {
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 1.25rem;
			img {
				width: 14px;
				height: 14px;
			}
			.main-tab {
				display: flex;
				align-items: center;
				gap: 14px;
				span {
					color: var(--color-text);
					font-size: var(--font-size6);
				}
			}
			.sub-tab {
				display: flex;
				align-items: center;
				gap: 14px;
				margin-left: 28px;
				span {
					color: var(--color-text);
					font-size: var(--font-size6);
				}
			}
		}
		a:-webkit-any-link {
			text-decoration: none;
		}
		.right-active {
			width: 5px;
			background-color: var(--color-main);
			height: 100%;
			position: absolute;
			right: 0;
		}
		.img-active {
			display: none;
		}
		&.active,
		&:hover {
			font-weight: 500 !important;
			&.main {
			}
			&.sub {
				background-color: var(--color-main-blur);
			}
			a {
				.main-tab {
					span {
						color: var(--color-text);
						font-weight: bold !important;
					}
				}
				.sub-tab {
					span {
						color: var(--color-main);
						font-weight: bold !important;
					}
				}
			}
			.img-deactive {
				display: none;
			}
			.img-active {
				display: block;
			}
		}
	}

	.section {
		width: calc(100% - var(--side-width));
		height: 100%;
		background-color: var(--color-background);
		padding: 24px;
		margin-left: var(--side-width);
	}
}
