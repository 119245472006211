.threeElements {
  @media screen and (max-width: 1023px) {
    height: 260px !important;
   }

}
.twoElements {
  @media screen and (max-width: 1023px) {
    height: 280px !important;
   }
}