@import '../../Assets//style//variables.scss';
.about {
    font-family: Montserrat-Regular;

    &-container {
        padding-bottom: 125px;
    }
    &-heading {
        margin: 99px 30px 30px 30px;
        height: 65px;
    }

    &-banner1 {
        img {
            width: 100%;
            max-height: 800px;
            object-fit: cover;
        }
    }
    &-info1 {
        margin: 70px 30px 150px 30px;
        display: flex;
        justify-content: space-between;

        &-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-shrink: 1;
        }
        &-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            flex-shrink: 1;

            width: 752px;
            font-family: Montserrat-Regular;
            font-size: 24px;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
        }
        &-text1 {
            p {
                margin-bottom: 0;
                color: #111;
                // letter-spacing: 0.205px;
                font-family: Montserrat-Regular;
                font-size: 24px;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
            }
        }
        &-text2 {
            p {
                color: #111;
                margin-bottom: 0;
                font-family: Montserrat-Regular;
                font-size: 24px;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
            }
        }
    }
    &-video {
        img {
            width: 100%;
            height: 1080px;
            object-fit: cover;
        }
    }
    &-info2 {
        margin: 70px 30px 180px 30px;
        display: flex;
        justify-content: space-between;
        gap: 35px;
        &-left {
            width: 909px;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            gap: 23px;

            &-text {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                font-size: 24px;
                text-align: left;
                color: #111;
                p {
                    margin-bottom: unset !important;
                    line-height: 1.5;
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #111;

                    // letter-spacing: 1.4px;
                }
            }
        }
        &-right {
            flex-shrink: 1;
            width: 909px;
            &-text {
                font-size: 24px;
                line-height: 1;
                text-align: left;
                letter-spacing: normal;
                color: #111;
                font-stretch: normal;
                font-style: normal;
                p {
                    margin-bottom: unset !important;
                    font-family: Montserrat-Regular;
                    line-height: 1.48;
                    font-size: 24px;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #111;
                }
            }
        }
    }
    &-banner2 {
        img {
            width: 100%;
            max-height: 720px;
            object-fit: cover;
        }
    }
    &-info3 {
        margin: 70px 75px 0 27px;

        display: flex;
        justify-content: space-between;
        &-left {
            width: auto;
            flex-shrink: 1;
            &-text {
            }
        }
        &-right {
            width: auto;
            flex-shrink: 1;
            &-text {
                .line1 {
                    display: flex;
                    align-items: end;
                    gap:5px;
                    span {
                        display: flex;
                        align-items: center;
                    }
                }
                .line2 {
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.48;
                    letter-spacing: normal;
                    text-align: left;
                    margin-top: 10px;
                    p {
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1279.98px) {
        &-container {
            padding-bottom: 100px;
        }

        &-banner1 {
            img {
                height: auto;
            }
        }
        &-heading {
            margin: 50px 30px 30px 30px;
        }
        &-info1 {
            margin: 50px 30px 100px 30px;

            &-left {
                flex: 0.3;
            }
            &-right {
                gap: 50px;
                flex: 0.7;
            }
        }
        &-video {
            img {
                height: auto;
            }
        }
        &-info2 {
            margin: 50px 30px 100px 30px;
        }
        &-info3 {
            margin: 50px 30px 0px 30px;
            &-left {
                flex: 0.3;
            }
            &-right {
                flex: 0.7;
                &-text {
                    .line1 {
                        .img-arrow {
                            max-width: 25px;
                            max-height: 25px;
                        }
                    }
                    .line2 {
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023.98px) {
        &-container {
            padding-bottom: 50px;
        }
    
        &-heading {
            margin: 50px 15px 0px 15px;
        }
        &-info1 {
            margin: 30px 15px 30px 15px;

            &-left {
                flex: 0.3;
            }
            &-right {
                flex: 0.7;
                gap: 30px;
            }
            &-text1 {
                p {
                    font-size: 16px;
                }
            }
            &-text2 {
                p {
                    font-size: 16px;
                }
            }
        }
        &-info2 {
            margin: 30px 15px 30px 15px;
            &-left {
                &-text {
                    p {
                        font-size: 16px;
                    }
                }
            }
            &-right {
                &-text {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
        &-info3 {
            margin: 30px 15px 0px 15px;
            &-left {
                flex: 0.3;
            }
            &-right {
            }
            &-right {
                flex: 0.7;
                &-text {
                    .line1 {
                    }
                    .line2 {
                        font-size: 16px;
                        line-height: 1.48;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) and (min-width: 481px) {
        &-info3 {
            &-left {
            }
            &-right {
                &-text {
                    .line1 {
                        .img-arrow {
                            max-width: 15px;
                            max-height: 15px;
                        }
                    }
                    .line2 {
                    }
                }
            }
        }

    
    }
    
    @media screen and (max-width: 767.98px) {
        &-container {
            padding-bottom: 30px;
        }

        &-info1 {
            margin: 30px 15px 30px 15px;

            flex-direction: column;

            &-left {
                flex: 0.3;
                margin-bottom: 30px;
            }
            &-right {
                flex: 0.7;
                width: auto;
                // max-width: 400px;
            }
        }
        &-info2 {
            margin: 30px 15px 30px 15px;
            flex-direction: column;

            &-left {
                width: auto;
            }
            &-right {
                width: auto;
                // max-width: 400px;
            }
        }
        &-info3 {
            margin: 30px 15px 0px 15px;
            flex-direction: column;
            &-left {
                margin-bottom: 30px;
            }
            &-right {
            }
        }
    }
}
