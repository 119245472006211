body {
	background-color: var(--color-background);
}

.login-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	row-gap: 30px;
	.logo {
		font-size: var(--font-size1);
		color: var(--color-text);
	}
	.login-box {
		display: flex;
		flex-direction: column;
		row-gap: 30px;
		.link-area {
			display: flex;
			justify-content: space-around;
			font-size: var(--font-size6);
			color: var(--color-main);
			cursor: pointer;
			> div {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
