.header-wrapper {
	height: var(--header-height);
	background-color: var(--color-white);
	border-bottom: 1px solid #f2f2f2;
	display: flex;
	align-items: center;
	&.header-active {
		.gnb-wrap {
			min-height: 5.25rem;
			background: #fff;
			border-bottom: 1px solid #eee;
			box-sizing: border-box;
		}
		.gnb-dimm {
			display: none;
		}
		.header-util span,
		.gnb-1depth-list span {
			color: #666;
		}
	}

	.header-content {
		display: flex;
		height: 100%;
		.header-logo {
			width: var(--side-width);
			align-self: center;
			text-align: center;
			cursor: pointer;
			> img {
				height: 40px;
			}
		}
		.content-container {
			display: flex;
			gap: 30px;
			align-items: center;
			padding-left: 26px;
			.top-menu {
				position: relative;
				height: 100%;
				font-size: var(--font-size5);
				font-weight: 500;
				a {
					height: 100%;
					display: flex;
					align-items: center;
					color: var(--color-text);
					text-decoration: none;
				}
				a:hover {
					color: var(--color-main);
					cursor: pointer;
				}
				&.active {
					a {
						color: var(--color-main);
					}
				}
				.bottom-active {
					position: absolute;
					height: 5px;
					background-color: var(--color-main);
					width: 100%;
					bottom: 0;
				}
			}
		}
	}
}
