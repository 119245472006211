.back-to-top {
  position: fixed;
  bottom: 35px;
  right: 30px;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 100%;

  z-index: 9999;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
