.alert-modal {
    .MuiDialog-container {
        .MuiDialog-paper {
            min-width: 240px;
            max-width: 480px;
            min-height: 100px;
            max-height: 192px;
            padding: 32px;
            justify-content: space-between;
            .modal-content {
                font-size: var(--font-size6);
                white-space: pre-line;
                text-align: center;
            }
            .button-area {
                display: flex;
                justify-content: flex-end;
                column-gap: 10px;
            }
        }
    }
}