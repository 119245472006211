.text-underline {
  font-size: 54px;
  line-height: 64px;
  font-weight: 600;
  color: #111111;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #111111;
  }
}
