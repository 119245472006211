@import "../../Assets/style/variables";

.head-more{
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width:768px) {
        flex-direction: column;
    }
    p,h3{
        margin-bottom: unset;
    }
    display: flex;
    &_title{
        font-size:calc( 54px - 10%);
    }
    &_decs{
        font-size:calc( 24px - 10%);
        max-width: 850px;
        @media (max-width:1024px) {
            font-size:calc( 22px - 10%);
        }
        @media (max-width:900px) {
            font-size:calc( 20px - 10%);
        }
        @media (max-width:768px) {
            margin-bottom: 20px;
            font-size:calc( 18px - 10%);
        }
        @media (max-width:480px) {
            margin-bottom: 10px;
            font-size:calc( 16px - 10%);
        }
    }
    &_link{
        &>*{
            font-family: Montserrat-SemiBold;
            color: map-get($color, main );
            text-decoration: underline;
            transition: .3s ease;
            span{
                transition: .3s ease;
                &::after{
                    transition: .3s ease;
                }
            }
            &:hover{
                span{
                    color: map-get($color, red-1 ) !important;
                    &::after{
                        background-color: map-get($color, red-1 ) !important;
                    }
                }
                
            }
        }
    }
}