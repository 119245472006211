@font-face {
    font-family: "Montserrat-Black";
    src: url("./Montserrat/Montserrat-Black.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-BlackItalic";
    src: url("./Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-Bold";
    src: url("./Montserrat/Montserrat-Bold.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-BoldItalic";
    src: url("./Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-ExtraBold";
    src: url("./Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-ExtraBoldItalic";
    src: url("./Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-ExtraLight";
    src: url("./Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-ExtraLightItalic";
    src: url("./Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-Italic";
    src: url("./Montserrat/Montserrat-Italic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-Light";
    src: url("./Montserrat/Montserrat-Light.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-LightItalic";
    src: url("./Montserrat/Montserrat-LightItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-Medium";
    src: url("./Montserrat/Montserrat-Medium.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-MediumItalic";
    src: url("./Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-Regular";
    src: url("./Montserrat/Montserrat-Regular.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("./Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-SemiBoldItalic";
    src: url("./Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-Thin";
    src: url("./Montserrat/Montserrat-Thin.ttf") format("truetype");
    
}
@font-face {
    font-family: "Montserrat-ThinItalic";
    src: url("./Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
    
}

  