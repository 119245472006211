.MuiTextField-root {
	background-color: #ffffff;
	.MuiOutlinedInput-root {
		border-radius: 2px !important;
		height: 100%;
		input {
			font-size: var(--font-size6);
			&::placeholder {
				font-size: var(--font-size6);
			}
		}
		textarea {
			font-size: var(--font-size6);
			&::placeholder {
				font-size: var(--font-size6);
			}
		}
	}
}
