.change-order-popup {
    .MuiDialog-container {
        .MuiDialog-paper {
            max-width: calc(100% - 100px);
            height: auto;
            .title-area {
                font-weight: 500;
                font-size: var(--font-size5);
                padding: 21px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                > svg {
                    cursor: pointer;
                }
            }
            .content-area {
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                padding: 0px 20px;
                background-color: var(--color-background);
                font-size: var(--font-size5);
                .loading {
                    display: flex;
                    height: 100px;
                    justify-content: center;
                    align-items: center;
                }
            }
            .button-area {
                display: flex;
                justify-content: flex-end;
                column-gap: 10px;
                padding: 16px 24px;
            }
        }
    }
}

.order-card {
    display: flex;
    align-items: center;
    gap: 10px;
    .index {
        width: 50px;
    }
    .name {
        flex: 1;
    }
    > img {
        width: 50px;
        height: fit-content;
    }
}
