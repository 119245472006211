.page-title {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 15px;
	.page-title-main {
		font-size: var(--font-size4);
		font-weight: 700;
		color: #333333;
	}
	.page-title-sub {
		font-size: var(--font-size6);
		color: #999999;
	}
}


.header-text {
	h5 {
		font-size: var(--font-size5);
	}
}
