@import '../../../Assets/style/variables';

.allItem {
    width: auto;
    display: flex;
    justify-content: space-between;
    margin: 0 30px;

    .allItem-menu {
        flex: 0.2;

        .menu {
            margin-bottom: 30px;

            .side-sub-title {
                margin-bottom: 50px;
            }

            &-bigTitle {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 15px;
                display: flex;
                align-items: center;
                gap: 5px;

                span {
                    width: 10px;
                    height: 2px;
                    background-color: black;
                }
            }

            &-subTitle {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 200px;
                margin-bottom: 10px;
                color: black;
                transition: 400ms all;
                cursor: pointer;

                &:hover {
                    color: map-get($map: $color, $key: red-1);
                }
            }

            .subTitleActive {
                color: map-get($map: $color, $key: red-1);
            }
        }
    }

    .allItem-list {
        flex: 1;
        position: relative;

        .list {
            margin-bottom: 50px;

            .list-element {
                margin-bottom: 60px;
            }

            &-bigTitle {
                font-weight: bold;
                font-size: 20px;
                display: flex;
                align-items: center;
                gap: 5px;
                // margin-top : 50px;
                margin-bottom: 30px;

                span {
                    width: 15px;
                    height: 4px;
                    background-color: black;
                }
            }

            &-element {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 25px 25px;

                .img {
                    position: relative;
                    max-width: 100%;
                    max-height: 100%;
                    overflow: hidden;

                    img {
                        position: absolute;
                    }
                }

                .element {
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    align-items: center;
                    text-align: center;

                    .img-box {
                        width: 100%;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            &:before {
                                content: '';
                                opacity: 0.5;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: #040404;
                                transition: all 0.5s;
                            }
                        }
                    }
                }

                .element-title {
                    font-weight: bold;
                    margin-top: 15px;
                    margin-bottom: 5px;
                    height: 80px;
                }

                .element-subTitle {
                    color: gray;
                }
            }
        }
    }

    @media screen and (max-width: 1279.98px) {
        .allItem-menu {
            flex: 0.15;
        }

        .allItem-list {
            flex: 0.8;

            .list {
                &-bigTitle {
                    span {}
                }

                &-element {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 30px;



                    .element-title {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .element-subTitle {
                        color: gray;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        flex-direction: column;

        .allItem-menu {
            flex: 0.15;
            display: none;
        }

        .allItem-list {
            flex: 0.8;

            .list {
                &-bigTitle {
                    span {}
                }

                &-element {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 25px 25px;

                    .element {
                        img {
                            margin-bottom: 10px;
                            height: unset;
                        }
                    }

                    .element-title {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .element-subTitle {
                        color: gray;
                    }
                }
            }
        }
    }
}