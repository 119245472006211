.button {
  cursor: pointer;
  &.default {
    color: var(--color-white);
    border: 1px solid var(--color-main);
    background-color: var(--color-main);
    &:hover {
      background-color: var(--color-white);
      color: var(--color-main);
    }
  }
  &.white {
    color: var(--color-text);
    border: 1px solid var(--color-border);
    background-color: var(--color-btn-white);
    &:hover {
      background-color: var(--color-main);
      color: var(--color-white);
    }
  }
  &.disabled {
    color: var(--color-text);
    border: 1px solid var(--color-btn-disabled);
    background-color: var(--color-btn-disabled);
    cursor: not-allowed;
  }

  line-height: 30px;
  font-size: var(--font-size6);
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-border);
  padding: 5px;
}
