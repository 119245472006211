@import '../../Assets/style/variables';

.default-button {
  height: 43px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 15px;
  color: map-get($map: $color, $key: main);
  border: 2px solid map-get($map: $color, $key: main);
  border-radius: 2px;

  transition: all 0.3s;
  &.active {
    color: #fff !important;
    background-color: #000 !important;
    border: 2px solid map-get($map: $color, $key: b) !important;
  }
  &:hover {
    color: #000;
    background-color: #fff;
    border: 2px solid #000;
  }
}
