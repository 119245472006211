$family: (
  primary: "utm-avo",
  // second: "Open+Sans"
);

$montserrat: "Montserrat", sans-serif;

$header-height: 72px;

$color: (
  // theme
  main: #111111,
  //color
  gray-1: #f1f2f5,
  black-1: #040404,
  red-1: #ff2400,
  // required
  b: #000,
  w: #fff,
  x: #f6f6f6,
  // notification
);

$shadow: (
  s1: 0 3px 6px rgba(0, 0, 0, 0.16),
  s2: 0 4px 10px rgba(0, 0, 0, 0.16),
);

$space: (
  120: 120px,
  82: 82px,
  64: 64px,
  60: 60px,
  56: 56px,
  54: 54px,
  48: 48px,
  45: 45px,
  42: 42px,
  40: 40px,
  38: 38px,
  36: 36px,
  32: 32px,
  24: 24px,
  20: 20px,
  16: 16px,
  15: 15px,
  12: 12px,
  10: 10px,
  5: 5px,
  0: 0px,
);

///////////////////// PAGE ////////////////////////////

* {
  --xxl: 1407px;
  --xl: 1262px;
  --md: 600px;
  --sm: 415px;
  --xs: 320px;

  --side-width: 209px;
  --header-height: 60px;

  --font-size7: 12px;
  --font-size6: 14px;
  --font-size5: 16px;
  --font-size4: 20px;
  --font-size1: 32px;

  --color-main: #199dfb; //메인 색
  --color-main-blur: #97d3fd;
  --color-text: #494e54; //기본적인 글씨 색
  --color-border: #494e54;
  --color-background: #f2f2f2;
  --color-white: #ffffff;

  --color-btn-white: #ffffff;

  --color-main-hover: #ffffff;
  --color-blue-hover: #97d3fd;
  --color-gray-hover: #b3b4b6;

  --color-table-header: #fafafa;
  --color-table-body: #ffffff;
  --color-btn-disabled: #959595;
}

///////////////////// ADMIN ////////////////////////////
