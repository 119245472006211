@import "../../../Assets/style/variables";

.home-section_1{
    width: 100%;
    
   
    p,h3{
        margin-bottom: unset;
    }
    .w-7{
        width: 65%;
        flex: 0 0 65%;
        @media (max-width:768px) {
            width: 100%;
            flex: 0 0 100%;
            
        }
    }
    .w-3{
        width: 35%;
        flex: 0 0 35%;
        @media (max-width:768px) {
            width: 100%;
            flex: 0 0 100%;
            
        }
    }
    &-wrapper{
        // max-width: 1920px;
        margin: auto;
        padding: 100px 30px;
        @media (max-width:1024px) {
            padding: 80px 15px;
            
        }
        @media (max-width:900px) {
            padding: 60px 15px;
            
        }
        @media (max-width:768px) {
            padding: 40px 15px;
            
        }
    }
    .title-head{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 180px;
        flex-wrap: wrap;
        @media (max-width:1200px) {
            margin-top: 130px;
            
        }
        @media (max-width:1024px) {
            margin-top: 80px;
            
        }
        @media (max-width:900px) {
            margin-top: 30px;
            
        }
        &>*{
            display: flex;
            align-items: center;
            
        }
        h3{
            font-size: calc(100px - 10%);
            font-weight: 600;
            @media (max-width:1024px) {
                font-size: calc(80px - 10%);
                
            }
            @media (max-width:900px) {
                font-size: calc(60px - 10%);
                
            }
            @media (max-width:768px) {
                font-size: calc(40px - 10%);
                
            }
            @media (max-width:320px) {
                font-size: calc(30px - 10%);
                
            }
        }
        &_right{
            @media (max-width:768px) {
               display: none;
                
            }
        }
    }
    .home-1-about{
        display: flex;
        flex-wrap: wrap;
        &_left{
            .image{
                width: 100%;
                position: relative;
                padding-bottom: 60%;
                &-wrapper{
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }
        &_right{
            // height: 100%;
            background-color: map-get($color, gray-1 ) ;
            padding: 40px;
            display: flex;
            flex-direction: column;
            @media (max-width:1200px) {
                padding: 30px;
            }
            @media (max-width:900px) {
                padding: 20px;
            }
            *{
                font-size: calc(18px - 10%);
                @media (max-width:768px) {
                    font-size: calc(16px - 10%);
                }
            }
            &-decs>*:not(:last-child){
                margin-bottom: 15px;
                @media (max-width:768px) {
                    margin-bottom: 10px;
                }
            }
            &-link{
                margin-top: auto;
                @media (max-width:768px) {
                    margin-top: 20px;
                }
                &>*{
                    color: map-get($color, b );
                }
            }
        }
    }
    .home-1-intro{
        display: flex;
        flex-wrap: wrap;
        &_left{
            padding-top: 150px;
            @media (max-width:900px) {
                padding-top: 100px;
            }
            @media (max-width:768px) {
                padding:15px;
                background-color: map-get($color, gray-1 );
                position: relative;
                &::after{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    left: 0;
                    height: 1px;
                    content: "";
                    background-color: map-get($color, main );
                }
            }
            &-decs{
                padding-right: 50px;
                font-size: 2.4rem;
                // font-size:24px;
               
                // @media (max-width:1024px) {
                //     font-size:22px;
                // }
                // @media (max-width:900px) {
                //     font-size:20px;
                // }
                // @media (max-width:768px) {
                //     padding-right: 0;
                //     font-size:18px;
                //     // color: map-get($color, w );
                // }
                // @media (max-width:480px) {
                //     font-size:16px;
                // }
                p{
                    margin-top: 10px;
                }
            }
        }
        &_right{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: map-get($color, b );
            min-height: 150px;
            h3{
                color: map-get($color, w );
                font-size: calc(36px - 10%);
            }
        }
    }
}