.admin-works-wrapper,
.admin-works-put-wrapper {
  padding-bottom: 100px;
  .data-grid-area {
    background-color: #ffffff;
    margin-top: 24px;
    padding: 24px;
    .button-area {
      display: flex;
      column-gap: 15px;
    }
    .thumbnail {
      width: 48px;
      height: 48px;
    }
    .clickable {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .input-grid-area {
    background-color: #ffffff;
    margin-top: 24px;
    padding: 24px;
    .row {
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid var(--color-table-header);
      font-size: var(--font-size6);
      margin: 0 !important;
      .item-key {
        width: 195px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-table-header);
        font-weight: 700;
      }
      .item-value {
        width: calc(100% - 195px);
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        background-color: var(--color-table-body);
        padding-left: 20px;
        row-gap: 5px;
        .tip {
          font-size: 8px;
          color: red;
        }
        .item-value-col {
          display: flex;
          column-gap: 10px;
          .btn-cols {
            display: flex;
          }
        }
      }
    }
    & .row-50 {
      height: 50px;
    }
    & .row-70 {
      height: 70px;
    }
    & .row-auto {
      height: auto;
    }
  }

  .button-area {
    display: flex;
    column-gap: 15px;
    justify-content: center;
    margin-top: 22px;
    margin-bottom: 22px;
  }
}
