@import '../../Assets/style/variables';

#footer {
  background-color: map-get($map: $color, $key: gray-1);
  .footer-container {
    margin: 0 30px;
  }
  .footer-main {
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .social-list {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .social-link,
  .copy-right {
    text-transform: capitalize;
    color: map-get($map: $color, $key: main);
    font-size: 16px;
    font-weight: 600;
  }

  .social-link {
    &:hover {
      color: map-get($map: $color, $key: red-1);
    }
  }

  @media screen and (max-width: 1024px) {
    .footer-main {
      flex-direction: column;
      padding: 50px 0 30px;
      height: auto;
      .copy-right {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .footer-main {
      .social-link,
      .copy-right {
        font-size: 12px;
      }
      .social-list {
        gap: 6px !important;
      }
    }
  }
}
