@import "../../../Assets/style/variables";

.home-section_3{
    width: 100%;
    background-color: map-get($color, w );
    p,h3{
        margin-bottom: unset;
    }
    &>*,a{
        font-size: 18px;
        // color: map-get($color, w );
        transition: .3s ease;
    }
    .w-65{
        width: 65%;
        flex: 0 0 65%;
        transition: .3s ease;
        @media (max-width:768px) {
            width: 100%;
            flex: 0 0 100%;
            
        }
    }
    .w-35{
        width: 35%;
        flex: 0 0 35%;
        transition: .3s ease;
        @media (max-width:768px) {
            width: 100%;
            flex: 0 0 100%;
            
        }
    }
    &-wrapper{
        // max-width: 1920px;
        margin: auto;
        padding: 100px 30px;
        transition: .3s ease;
        @media (max-width:1200px) {
            padding: 50px 15px;
        }
        @media (max-width:900px) {
            padding: 50px 15px;
        }
        @media (max-width:768px) {
            padding: 30px 15px ;
        }
    }
    .home-3{
        display: flex;
        flex-wrap: wrap;
        &_title{
            margin-top: 85px;
            margin-bottom: 200px;
            max-width: 1070px;
            margin-left: auto;
            text-indent: 180px;
            transition: .3s ease;
            span{
                text-indent: 0;
            }
            @media (max-width:1200px) {
                margin-bottom: 150px;
            }
            @media (max-width:900px) {
                margin-bottom: 100px;
                text-indent: 130px;
                margin-top: 35px;
            }
            @media (max-width:768px) {
                margin-bottom: 50px;
                text-indent: 50px;
                font-size: 16px;
            }
        }
        &-items{
            
            @media (max-width:768px) {
                margin-bottom: 30px;
                background-color: map-get($color, gray-1);
            }
            .home-3-img{
                width: 100%;
                padding-bottom: 55%;
                position: relative;
                .img-wrapper{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        .right{
            padding-left: 15px;
            @media (max-width:768px) {
                padding-left: unset;
            }
            align-self: end;
        }
        .left{
            padding-right: 15px;
            @media (max-width:768px) {
                padding-right: unset;
            }
        }
    }
}