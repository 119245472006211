.password-change-popup {
    .MuiDialog-container {
        .MuiDialog-paper {
            width: 500px;
            height: auto;
            .title-area {
                font-weight: 500;
                font-size: var(--font-size5);
                padding: 21px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                > svg {
                    cursor: pointer;
                }
            }
            .content-area {
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                padding: 24px;
                background-color: var(--color-background);
                font-size: var(--font-size5);
                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .row-key {

                    }
                    .row-input {

                    }
                    
                }
                .row-error {
                    font-size: var(--font-size7);
                    color: red;
                    margin-top: 5px;
                }
            }
            .button-area {
                display: flex;
                justify-content: flex-end;
                column-gap: 10px;
                padding: 16px 24px;
            }
        }
    }
   
}