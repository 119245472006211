.search-filter {
  background-color: #ffffff;
  margin-top: 24px;
  padding: 24px;
  .row {
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--color-table-header);
    font-size: var(--font-size6);
    margin: 0 !important;
    .key {
      width: 195px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-table-header);
      font-weight: 700;
    }
    .item-area {
      width: calc(100% - 195px);
      height: 100%;
      display: flex;
      align-items: center;
      background-color: var(--color-table-body);
      padding-left: 20px;
      .search-wrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
      }
      .range-picker-wrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
      }
    }
  }
  .button-area {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    margin-top: 15px;
  }
}
