.MuiDataGrid-iconSeparator {
	display: none !important;
}
.css-1xy1myn-MuiDataGrid-root {
	font-size: var(--font-size5);
	.MuiDataGrid-row {
		&.Mui-selected {
			background-color: #e3e5e9 !important;
		}
	}
}
.MuiDataGrid-columnHeaderTitle {
	font-size: var(--font-size5);
}
.MuiDataGrid-cell--withRenderer span{
	font-size: var(--font-size7);
}
.data-grid-wrapper {
	.search-result {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: var(--font-size5);
		color: #494d54;
		font-weight: 700 !important;
		margin-bottom: 11px;
		.search-result-text {
			> span {
				color: #fa575e;
			}
		}
	}
	// .data-grid-v2 {
	// 	background-color: #ffffff;
	// 	display: flex;
	// 	width: 100%;
	// 	height: 100%;
		
	// }
}
