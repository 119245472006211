.musicVideo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    padding: 0 30px;

    .works-item {
        position: relative;
        cursor: pointer;

        .card-info{
            opacity: 0;
            }
            &:hover {
                .card-info{
                    opacity: 1;
                }
            &:before {
                content: '';
                opacity: 0.5;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #040404;
                transition: all 0.5s;
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        // max-height: 300px;
        object-fit: cover;
        cursor: pointer;

    }
 .card-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .card-title {
            color: #fff;
        }
        .card-content {
            margin-top: 20px;
            width: 100%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            p {
                margin-bottom: 0;
                font-family: Montserrat;
                font-size: 2.0rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
            }
        }
    }
    @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 25px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 10px;
        padding: 0 20px;
    }
}
// .activeDialogVideo {
//     display: flex;
//     flex-wrap: wrap;
//     width: 100%;
//     gap: 0;
    
//     .works-item  {
//         width: calc(20%);
//         padding: 10px;
//         .card-info{
//             opacity: 0;
//             }
//             &:hover {
                
//             &:before {
//                 content: '';
//                 opacity: 0.5;
//                 position: absolute;
//                 margin: 10px;
//                 top: 0;
//                 left: 0;
//                 right: 0;
//                 bottom: 0;
//                 background-color: #040404;
//                 transition: all 0.5s;
//             }
//         }
   
//     }
//     .card-info {
//         .card-content {
//             p {
//                 font-size: 1.2rem;
//             }
//         }
//     }

// }