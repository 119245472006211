@import "../../../Assets/style/variables";

.home-section_2{
    width: 100%;
    background-color: map-get($color, main );
    p,h3{
        margin-bottom: unset;
    }
    &>*,a{
        font-size:calc( 18px - 10%);
        color: map-get($color, w );
        transition: .3s ease;
    }
    .w-7{
        width: 70%;
        flex: 0 0 70%;
    }
    .w-3{
        width: 30%;
        flex: 0 0 30%;
    }
    &-wrapper{
        // max-width: 1920px;
        margin: auto;
        padding: 150px 30px;
        @media (max-width:1200px) {
            padding: 100px 15px;
        }
        @media (max-width:900px) {
            padding: 50px 15px;
        }
        @media (max-width:768px) {
            padding: 30px 15px;
        }
    }
    .home-2{
        &_title{
            margin-top: 100px;
            max-width: 331px;
            font-size:calc( 24px - 10%);
            font-size:calc( 18px - 10%);
            @media (max-width:1024px) {
                margin-top: 50px;
            }
           
            @media (max-width:768px) {
                font-size:calc( 16px - 10%);
                padding-right: 0;
            }
        }
        img{
            width: auto;
            height: auto;
        }
        &_images-1{
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin-bottom: 150px;
            transition: .3s ease;
            @media (max-width:1200px) {
                margin-bottom: 100px;
                margin-top: 30px;
            }
            @media (max-width:900px) {
                margin-bottom: 50px;
                margin-top: 30px;
            }
            .left{
                width: 60%;
                display: flex;
                flex-direction: column;
                transition: .3s ease;
                @media (max-width:768px) {
                    width: 100%;
                }
                .img-1{
                    transition: .3s ease;
                    width: 35%;
                    align-self: flex-end;
                    margin-right: 150px;
                    @media (max-width:1200px) {
                        width: 45%;
                    }
                    @media (max-width:900px) {
                        width: 100%;
                        margin-right: unset;
                    }
                    @media (max-width:768px) {
                        width: 100%;
                    }
                }
                .img-3{
                    margin-top: auto;
                    justify-self: end;
                    width: 100%;
                }
            }
            .right{
                width: 40%;
                margin-left: auto;
                display: flex;
                flex-direction: column;
                padding-left: 30px;
                transition: .3s ease;
                @media (max-width:768px) {
                    padding-left: unset;
                    width: 100%;
                    // margin-right: unset;
                }
                .img-2{
                    width: 60%;
                    margin-left: auto;
                    margin-bottom: 130px;
                    transition: .3s ease;
                    @media (max-width:1200px) {
                        margin-bottom: 80px;
                        width: 70%;
                    }
                    @media (max-width:900px) {
                        width: 100%;
                        margin: auto;
                        margin-bottom: 30px;
                        max-width: 500px ;
                    }
                }
                .decs{
                    margin-top: auto;
                    align-self: flex-end;
                    max-width: 460px;
                    margin-right: auto;
                    margin-left: auto;
                    @media (max-width:768px) {
                        font-size:calc( 16px - 10%);
                    }
                }
            }
            
            
        }
        &_images-2{
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .left{
                width: 60%;
                // flex: 0 0 60%;
                display: flex;
                align-items: center;
                padding-right: 6vw;
                transition: .3s ease;
                @media (max-width:768px) {
                    width: 100%;
                }
                .img-5{
                    width: 45%;
                    transition: .3s ease;
                    // min-width: 434px;
                    @media (max-width:1200px) {
                        
                    }
                }
                .decs{
                    width: 55%;
                    margin-bottom: 250px;
                    margin-left: 30px;
                    transition: .3s ease;
                    @media (max-width:1200px) {
                        margin-bottom: 200px;
                        
                    }
                    @media (max-width:900px) {
                        margin-bottom: 0;
                    }
                    @media (max-width:768px) {
                        font-size:calc( 16px - 10%);
                    }
                }
            }
            .right{
                width: 40%;
                margin-left: auto;
                display: flex;
                flex-direction: column;
                @media (max-width:768px) {
                    width: 100%;
                    margin-top: 30px;
                }
                .img-6{
                    width: 60%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 51px;
                    transition: .3s ease;
                    @media (max-width:1024px) {
                        width: 75%;
                    }
                    @media (max-width:900px) {
                        margin: auto;
                        width: 80%;
                    }
                    @media (max-width:768px) {
                        width: 100%;
                    }
                }
                
            }
            
            
        }
        &_images-3{
            display: flex;
            margin-top: -150px;
            transition: .3s ease;
            @media (max-width:1200px) {
                margin-top: -80px;
                    
            }
            @media (max-width:900px) {
                margin-top: 30px;
                    
            }
            @media (max-width:768px) {
                flex-direction: column-reverse;
                
            }
            .left{
                padding-top: 150px;
                width: 35%;
                margin-top: auto;
                transition: .3s ease;
                @media (max-width:1200px) {
                    padding-top: 80px;
                        
                }
                @media (max-width:900px) {
                    padding-top: 0;
                    
                }
                @media (max-width:768px) {
                    width: 100%;
                        
                }
                .decs{
                    max-width: 434px;
                    transition: .3s ease;
                    padding-right: 30px;
                    @media (max-width:768px) {
                        margin: auto;
                        padding: 30px 0;
                    }
                    @media (max-width:768px) {
                        font-size:calc( 16px - 10%);
                    }
                }
            }
            .right{
                width: 65%;
                transition: .3s ease;
                @media (max-width:768px) {
                    width: 100%;
                        
                }
            }
        }
    }
}