.sec-3_bottom{
    margin-top: 30px;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    flex-wrap: wrap;
    transition: .3s ease;
    @media (max-width:768px) {
        padding: 15px;
        margin-top: 10px;
    }
    &-title{
        align-self: start;
        justify-self:start;
        margin-bottom: 15px;
        margin-right: auto;
        @media (max-width:768px) {
            margin-bottom: 10px;
            width: 100%;
        }
        @media (max-width:480px) {
            margin-bottom: 5px;
        }
    }
    &-items{
        min-width: 50px;
        margin-right: auto;
        transition: .3s ease;
        @media (max-width:768px) {
            margin-bottom: 0;
            font-size: 16px;
        }
    }
}