.AllItemsPopup {
	background-color: white;
	z-index: 99999;
	// padding: 10px 30px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-name: opac;
	animation-name: opac;

	@-webkit-keyframes opac {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1 !important;
		}
	}

	@keyframes opac {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1 !important;
		}
	}

	&-contents {
		// padding-top: 30px;
		width: 100%;
		height: 100%;
		margin-top: 0;
		overflow-x: hidden;
		overflow-y: auto;
		box-sizing: border-box;
		display: grid;
		padding: 30px;
		grid-template-columns: 1fr 1fr;

		&:hover {
			&::-webkit-scrollbar-thumb {
				//   background-color: #000000;
			}
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			background-color: transparent;
			border-radius: 0.5rem;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&::-webkit-scrollbar-thumb {
			// background-color: $subColor;
			background-color: #e7e7e7;
			border-radius: 0.5rem;
		}

		.contents-details {
			.title-en {
				font-size: 26px;
				font-weight: bold;
			}

			.title-ko {
				font-size: 26px;
				font-weight: bold;
				padding-top: 20px;
			}

			.sub-detail {
				white-space: break-spaces;
				font-size: 16px;
				padding-top: 25px;

			}

			p {
				margin-bottom: 0;
			}
			@media screen and (max-width: 967px) {
				.title-en {
					font-size: 16px;
				}
	
				.title-ko {
					font-size: 16px;
					padding-top: 15px;
				}
	
				.sub-detail {
					font-size: 14px;
					padding-top: 20px;
				}
			}

		}
		@media screen and (max-width: 1279px) {
			grid-template-columns: 1fr ;
			row-gap: 20px;
		}
	}

	.columnItem {
		display: flex;
		flex-direction: column;
		padding: 30px;

		.AllItemsPopup-inners {
			padding-top: 30px
		}
	}
}

.firstDialog {
	padding: 0;

	.AllItemsPopup-contents {
		padding: 0 0 30px 0;
	}
}

.mainImage {
	width: 100%;
	height: auto;

	img {
		width: 100%;
		height: auto;
	}
}

.mainDesign {

	// padding-top: 30px;
	img {
		width: 100%;
	}
}

.overView {
	margin: 40px auto;
	color: #000000;
	padding: 0 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	.left-title {
		flex: 3;
	}

	.right-title {
		flex: 5;
		display: flex;
		align-items: flex-end;
	}

	.overViewWrap {
		display: flex;
		flex: 1;
		width: 100%;
		// margin-top: 40px;
		margin-bottom: 45px;
	}

	.title {
		white-space: nowrap;
		font-size: 2.5rem;
	}

	.border {
		width: 100%;
		// margin-left: 20px;
		border: none;
		height: 100%;
		border-bottom: 1px solid #000000;
		margin-bottom: 0;
	}

	.type {
		font-size: 12px;
		font-weight: 700;
		font-family: "GothamRoundedBold";
		margin-bottom: 15px;
	}

	.company {
		font-family: "GothamRoundedLigth";
		font-weight: bold;
		font-size: 25px;

		@media screen and (max-width: 767.98px) {
			font-size: 15px;
		}
	}

	.name {
		font-size: 25px;
	}
}

.mainVideo {
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;

	video {
		width: 100%;
		height: auto;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	body {
		width: 100%;
	}
}